body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.app-container{
   .login-container{
    display:flex;
    flex-direction:column;
    margin: 0 auto;

    .title{
      text-align:center;
      font-size: 23px;
      padding-top:30px;
    }

    .text{
      font-weight:600;
      padding-bottom:3px;
    }

    input{
      width:100%;
      margin-bottom: 7px;
      padding:8px 5px;
      outline:none;
      background: #1618230f;
      border: 1px solid #1618230f; 
      border-radius:3px;
      &:hover{
        background-color: #dedede;
      }
    }
    .input-2{
      position:relative;

      i{
         cursor:pointer;
         position:absolute;
         top:17px;
         right:0px;
      }
    }
    button{
      margin-top:15px;
      padding: 10px;
      border-radius:3px;
      outline:none;
      border:none;
      &.active{
        background-color: red;
        color:white
      }
      button[disabled=disabled],button:disabled{
        cursor: not-active
      }
    }
    .back{
      margin-top:20px;
      text-align:center;
      cursor:pointer;
    }
   }
}