.sort-header{
    display:flex;
    justify-content:space-between;

    i{
        cursor: pointer;
        padding: 0 2px;
    }
}

.add-new
{
    //display:flex;
    align-items:center;
    justify-content: space-between;

.group-btns{
    display:flex;
    gap:10px;
    i{
        padding: 0 2px;
    }
}
}
.customize-table{
    width:inherit;
    overflow-x: auto;
    
}

.pagination{
    justify-content: center;
}